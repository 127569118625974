import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

//TODO:remove aws
import "./config/aws";
import { AuthenticationContext } from "./context/AuthenticationContext";
import { AuthenticationService } from "./services/authentication-service";

import { Auth0Provider } from "@auth0/auth0-react";

const domNode = document.getElementById("root");
const root = createRoot(domNode);

const authenticationService = new AuthenticationService();

root.render(
  <React.StrictMode>
    <AuthenticationContext.Provider value={authenticationService}>
      <Auth0Provider
        domain="media-service-group.eu.auth0.com"
        clientId="KN6wFlr24CIZmZ2wfyYd1Gihvb6FNVhn"
        cacheLocation="localstorage"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://media-service-group.eu.auth0.com/api/v2/",
          scope: "read:cart openid profile email",
        }}
      >
        <Router>
          <App />
        </Router>
      </Auth0Provider>
    </AuthenticationContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
